import logo from '../assets/img/logo.svg'

export default function Navbar() {
    return (
        <>
            <nav className="navbar navbar-expand-lg navbar-dark border border-0 border-bottom border-dark">
                <div className="container">
                    <h3 className='navbar-brand fs-6 d-flex align-items-center'>
                        <img src={logo} className="me-2" alt="genbox" height={'28px'} />
                        Genbox <span className='fw-light'>Enterprise</span>
                    </h3>
                </div>
            </nav>
            <nav className="navbar navbar-dark navbar-expand-lg">
                <div className="container">
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                            <li className="nav-item">
                                <a className="nav-link" aria-current="page" href="https://genbox.academy">Academy</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" aria-current="page" href="https://medium.com/@risanussy07">Article</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" aria-current="page" href="mailto:hello@genbox.id">
                                    <i class="fa-regular fa-envelope me-2"></i>
                                    hello@genbox.id
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        </>
    );
}

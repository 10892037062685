import Navbar from "../components/Navbar";
import { useState } from 'react';


// assets
import a from "../assets/img/partners/pollux.svg"
import b from "../assets/img/partners/stara.svg"
import c from "../assets/img/partners/part.svg"
import d from "../assets/img/partners/crud.svg"
import e from "../assets/img/partners/blue.svg"

import robot from "../assets/img/robot.jpg"

import porto1 from "../assets/img/porto/a.jpg"
import porto2 from "../assets/img/porto/b.jpg"
import porto3 from "../assets/img/porto/c.jpg"
import porto4 from "../assets/img/porto/d.jpg"
import porto5 from "../assets/img/porto/e.jpg"
import porto6 from "../assets/img/porto/f.jpg"
import porto7 from "../assets/img/porto/g.jpg"
import porto8 from "../assets/img/porto/h.jpg"
import porto9 from "../assets/img/porto/i.jpg"
import porto10 from "../assets/img/porto/j.jpg"
import porto11 from "../assets/img/porto/k.jpg"
import porto12 from "../assets/img/porto/l.jpg"
import porto13 from "../assets/img/porto/m.jpg"
import porto14 from "../assets/img/porto/n.jpg"
import porto15 from "../assets/img/porto/o.jpg"
import porto16 from "../assets/img/porto/p.jpg"

import right from "../assets/img/ornament-right.png"
import left from "../assets/img/ornament-left.png"

import earth from "../assets/brand/earth.png"
import game from "../assets/brand/game.png"
import toga from "../assets/brand/toga.png"

export default function Home() {
    const [activeHero, setActiveHero] = useState(1);
    const [subject, setSubject] = useState("");

    const active = (heroNumber) => {
        document.querySelectorAll('.hero').forEach(hero => {
            hero.classList.remove('hero-active');
        });
        document.querySelector(`.hero-${heroNumber}`).classList.add('hero-active');
        setActiveHero(heroNumber);
    }

    const sendEmail = (e) => {
        e.preventDefault();
        window.location.href = "mailto:genbox22@gmail.com?subject=" + subject    
    }

    return (
        <>
            <header>
                <Navbar />
                <div className="container pt-3 position-relative">
                    <img src={left} alt="ornament" className="ornament-left" />
                    <img src={right} alt="ornament" className="ornament-right" />
                    <h1 className="text-center fw-light mb-4">
                        <b className="fw-bold font-grad">Gen</b>eration Out of The <b className="fw-bold font-grad">Box</b>.
                    </h1>
                    <div className="container-x">
                        <div className="d-flex gap-2">
                            <div onClick={() => active(1)} className={`bg-secondary rounded hero hero-1 ${activeHero === 1 ? 'hero-active' : ''}`}>
                                <div className="content position-relative">
                                    <h3 className="tshadow">Software House<br />Development & Branding</h3>
                                    <img src={earth} alt="brand" className="brand-img brand-img-1" />
                                </div>
                            </div>
                            <div onClick={() => active(2)} className={`bg-secondary rounded hero hero-2 ${activeHero === 3 ? 'hero-two' : ''} ${activeHero === 2 ? 'hero-active' : ''}`}>
                                <div className="content position-relative">
                                    <h3 className="tshadow">Game Studio <br/>Development & B2B</h3>
                                    <img src={game} alt="brand" className="brand-img brand-img-2" />
                                </div>
                            </div>
                            <div onClick={() => active(3)} className={`bg-secondary rounded hero hero-3 ${activeHero === 3 ? 'hero-active' : ''}`}>
                                <div className="content position-relative">
                                    <h3 className="tshadow">Academy<br />and Human resource</h3>
                                    <img src={toga} alt="brand" className="brand-img brand-img-3" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <br />
                    <p className="text-center text-white">Go build your own with us, under the auspices of <b>PT Generasi Digital Nasional</b></p>
                    <div className="d-flex justify-content-center gap-2">
                        <a href="https://wa.me/6285174072416" className="rounded px-3 py-2 text-decoration-none btn-main">Let's schedule our time</a>
                        <a href="https://drive.google.com/file/d/1-DAv7PKwhn1Im-gH64yOzJtKlI1SO3dC/view?usp=sharing" className="rounded px-3 py-2 text-decoration-none btn-outline-main">Company Profile</a>
                    </div>
                </div>
            </header>
            <div className="banner">
                <div className="container">
                    <div className="row">
                        <div className="col-md-2"></div>
                        <div className="col-md-4">
                            <img src={robot} alt="robot" className="w-100" />
                        </div>
                        <div className="col-md-4 d-flex align-items-center">
                            <div>
                                <small className="mb-3 d-flex fw-light fs-5">Genbox Enterprise.</small>
                                <h3 className="text-white">We're a <span className="font-grad fw-bold">#1 Best</span> Problem solve for the inovation and future.</h3>
                                <p className="text-white">We specialize in creating innovative solutions that enhance your business growth.</p>
                                <a href="https://wa.me/6285174072416" className="rounded fs-5 mt-5 d-flex align-items-center gap-3 py-2 text-decoration-none font-grad">
                                <div>
                                Our products<br/>under construction 
                                </div>
                                <i class="fa-solid fa-arrow-up-right-from-square"></i>
                                </a>
                            </div>
                        </div>
                        <div className="col-md-2"></div>
                    </div>
                    <div className="row mt-5">                        
                        <div className="col-md-2 mb-5"></div>
                        <div className="col-2 mb-5 text-custom m-resp-custom">
                            <h2 className="mb-2 text-center d-flex flex-column align-items-center justify-content-center"><i class="fa-regular fa-lightbulb fw-light mb-3 text-center"></i>3</h2>
                            <div className="text-stats">Product</div>
                        </div>
                        <div className="col-2 mb-5 text-custom m-resp-custom">
                            <h2 className="mb-2 text-center d-flex flex-column align-items-center justify-content-center">
                            <i class="fa-solid fa-parachute-box mb-3 text-center"></i>20+</h2>
                            <div className="text-stats">Project Development</div>
                        </div>
                        <div className="col-2 mb-5 text-custom m-resp-custom">
                            <h2 className="mb-2 text-center d-flex flex-column align-items-center justify-content-center">
                            <i class="fa-solid fa-building mb-3 text-center"></i>100+</h2>
                            <div className="text-stats">Partner & Client</div>
                        </div>
                        <div className="col-2 mb-5 text-custom m-resp-custom">
                            <h2 className="mb-2 text-center d-flex flex-column align-items-center justify-content-center">
                            <i class="fa-solid fa-graduation-cap mb-3 text-center"></i>102+</h2>
                            <div className="text-stats">Academy graduates</div>
                        </div>
                        <div className="col-md-2 mb-5"></div>
                    </div>
                </div>
            </div>          
            <h3 className="fw-light text-center mt-5 mb-4">
                Our services.
            </h3>
            <div className="container">
                <div className="row mt-5">
                    <div className="col-sm-6 col-md-4">
                        <div className="card mb-3 px-3 py-5 shadow-lg">
                            <h4 className="font-grad">Website Application </h4>
                            <div className="d-flex align-items-center">
                                <div><svg xmlns="http://www.w3.org/2000/svg" width="2em" height="2em" viewBox="0 0 256 256"><g fill="currentColor"><path d="M176 128a48 48 0 1 1-48-48a48 48 0 0 1 48 48" opacity="0.2"/><path d="M140 128a12 12 0 1 1-12-12a12 12 0 0 1 12 12"/></g></svg></div>
                                <div className="fw-light">Start from 500.000 IDR</div>
                            </div>
                            <div className="d-flex align-items-center">
                                <div><svg xmlns="http://www.w3.org/2000/svg" width="2em" height="2em" viewBox="0 0 256 256"><g fill="currentColor"><path d="M176 128a48 48 0 1 1-48-48a48 48 0 0 1 48 48" opacity="0.2"/><path d="M140 128a12 12 0 1 1-12-12a12 12 0 0 1 12 12"/></g></svg></div>
                                <div className="fw-light">Free 2x Maintenance</div>
                            </div>
                            <div className="d-flex align-items-center">
                                <div><svg xmlns="http://www.w3.org/2000/svg" width="2em" height="2em" viewBox="0 0 256 256"><g fill="currentColor"><path d="M176 128a48 48 0 1 1-48-48a48 48 0 0 1 48 48" opacity="0.2"/><path d="M140 128a12 12 0 1 1-12-12a12 12 0 0 1 12 12"/></g></svg></div>
                                <div className="fw-light">guarantee 1 year</div>
                            </div>
                        </div>
                    </div>     
                    <div className="col-sm-6 col-md-4">
                        <div className="card mb-3 px-3 py-5 shadow-lg">
                            <h4 className="font-grad">Mobile Application </h4>
                            <div className="d-flex align-items-center">
                                <div><svg xmlns="http://www.w3.org/2000/svg" width="2em" height="2em" viewBox="0 0 256 256"><g fill="currentColor"><path d="M176 128a48 48 0 1 1-48-48a48 48 0 0 1 48 48" opacity="0.2"/><path d="M140 128a12 12 0 1 1-12-12a12 12 0 0 1 12 12"/></g></svg></div>
                                <div className="fw-light">Start from 1.500.000 IDR</div>
                            </div>
                            <div className="d-flex align-items-center">
                                <div><svg xmlns="http://www.w3.org/2000/svg" width="2em" height="2em" viewBox="0 0 256 256"><g fill="currentColor"><path d="M176 128a48 48 0 1 1-48-48a48 48 0 0 1 48 48" opacity="0.2"/><path d="M140 128a12 12 0 1 1-12-12a12 12 0 0 1 12 12"/></g></svg></div>
                                <div className="fw-light">Free up to playstore</div>
                            </div>
                            <div className="d-flex align-items-center">
                                <div><svg xmlns="http://www.w3.org/2000/svg" width="2em" height="2em" viewBox="0 0 256 256"><g fill="currentColor"><path d="M176 128a48 48 0 1 1-48-48a48 48 0 0 1 48 48" opacity="0.2"/><path d="M140 128a12 12 0 1 1-12-12a12 12 0 0 1 12 12"/></g></svg></div>
                                <div className="fw-light">guarantee 1 year</div>
                            </div>
                        </div>
                    </div>     
                    <div className="col-sm-6 col-md-4">
                        <div className="card mb-3 px-3 py-5 shadow-lg">
                            <h4 className="font-grad">Game Development</h4>
                            <div className="d-flex align-items-center">
                                <div><svg xmlns="http://www.w3.org/2000/svg" width="2em" height="2em" viewBox="0 0 256 256"><g fill="currentColor"><path d="M176 128a48 48 0 1 1-48-48a48 48 0 0 1 48 48" opacity="0.2"/><path d="M140 128a12 12 0 1 1-12-12a12 12 0 0 1 12 12"/></g></svg></div>
                                <div  className="fw-light">Start from 3.500.000 IDR</div>
                            </div>
                            <div className="d-flex align-items-center">
                                <div><svg xmlns="http://www.w3.org/2000/svg" width="2em" height="2em" viewBox="0 0 256 256"><g fill="currentColor"><path d="M176 128a48 48 0 1 1-48-48a48 48 0 0 1 48 48" opacity="0.2"/><path d="M140 128a12 12 0 1 1-12-12a12 12 0 0 1 12 12"/></g></svg></div>
                                <div  className="fw-light">Asset not include</div>
                            </div>
                            <div className="d-flex align-items-center">
                                <div><svg xmlns="http://www.w3.org/2000/svg" width="2em" height="2em" viewBox="0 0 256 256"><g fill="currentColor"><path d="M176 128a48 48 0 1 1-48-48a48 48 0 0 1 48 48" opacity="0.2"/><path d="M140 128a12 12 0 1 1-12-12a12 12 0 0 1 12 12"/></g></svg></div>
                                <div  className="fw-light">guarantee 1 year</div>
                            </div>
                        </div>
                    </div> 
                </div>    
                <div className="row">           
                    <div className="col-sm-6 col-md-4">
                        <div className="card mb-3 px-3 py-5 shadow-lg">
                            <h4 className="font-grad">IoT Development</h4>
                            <div className="d-flex align-items-center">
                                <div><svg xmlns="http://www.w3.org/2000/svg" width="2em" height="2em" viewBox="0 0 256 256"><g fill="currentColor"><path d="M176 128a48 48 0 1 1-48-48a48 48 0 0 1 48 48" opacity="0.2"/><path d="M140 128a12 12 0 1 1-12-12a12 12 0 0 1 12 12"/></g></svg></div>
                                <div className="fw-light">Start from 1.000.000 IDR</div>
                            </div>
                            <div className="d-flex align-items-center">
                                <div><svg xmlns="http://www.w3.org/2000/svg" width="2em" height="2em" viewBox="0 0 256 256"><g fill="currentColor"><path d="M176 128a48 48 0 1 1-48-48a48 48 0 0 1 48 48" opacity="0.2"/><path d="M140 128a12 12 0 1 1-12-12a12 12 0 0 1 12 12"/></g></svg></div>
                                <div className="fw-light">Module not include</div>
                            </div>
                            <div className="d-flex align-items-center">
                                <div><svg xmlns="http://www.w3.org/2000/svg" width="2em" height="2em" viewBox="0 0 256 256"><g fill="currentColor"><path d="M176 128a48 48 0 1 1-48-48a48 48 0 0 1 48 48" opacity="0.2"/><path d="M140 128a12 12 0 1 1-12-12a12 12 0 0 1 12 12"/></g></svg></div>
                                <div className="fw-light">guarantee 1 year</div>
                            </div>
                        </div>
                    </div>             
                    <div className="col-sm-6 col-md-4">
                        <div className="card mb-3 px-3 py-5 shadow-lg">
                            <h4 className="font-grad">Blockchain / Smart Contract</h4>
                            <div className="d-flex align-items-center">
                                <div><svg xmlns="http://www.w3.org/2000/svg" width="2em" height="2em" viewBox="0 0 256 256"><g fill="currentColor"><path d="M176 128a48 48 0 1 1-48-48a48 48 0 0 1 48 48" opacity="0.2"/><path d="M140 128a12 12 0 1 1-12-12a12 12 0 0 1 12 12"/></g></svg></div>
                                <div className="fw-light">Start from 8.600.000 IDR</div>
                            </div>
                            <div className="d-flex align-items-center">
                                <div><svg xmlns="http://www.w3.org/2000/svg" width="2em" height="2em" viewBox="0 0 256 256"><g fill="currentColor"><path d="M176 128a48 48 0 1 1-48-48a48 48 0 0 1 48 48" opacity="0.2"/><path d="M140 128a12 12 0 1 1-12-12a12 12 0 0 1 12 12"/></g></svg></div>
                                <div className="fw-light">Free Source code</div>
                            </div>
                            <div className="d-flex align-items-center">
                                <div><svg xmlns="http://www.w3.org/2000/svg" width="2em" height="2em" viewBox="0 0 256 256"><g fill="currentColor"><path d="M176 128a48 48 0 1 1-48-48a48 48 0 0 1 48 48" opacity="0.2"/><path d="M140 128a12 12 0 1 1-12-12a12 12 0 0 1 12 12"/></g></svg></div>
                                <div className="fw-light">guarantee 1 year</div>
                            </div>
                        </div>
                    </div>             
                    <div className="col-sm-6 col-md-4">
                        <div className="card mb-3 px-3 py-5 shadow-lg">
                            <h4 className="font-grad">Artificial intelligence</h4>
                            <div className="d-flex align-items-center">
                                <div><svg xmlns="http://www.w3.org/2000/svg" width="2em" height="2em" viewBox="0 0 256 256"><g fill="currentColor"><path d="M176 128a48 48 0 1 1-48-48a48 48 0 0 1 48 48" opacity="0.2"/><path d="M140 128a12 12 0 1 1-12-12a12 12 0 0 1 12 12"/></g></svg></div>
                                <div className="fw-light">Start from 3.500.000 IDR</div>
                            </div>
                            <div className="d-flex align-items-center">
                                <div><svg xmlns="http://www.w3.org/2000/svg" width="2em" height="2em" viewBox="0 0 256 256"><g fill="currentColor"><path d="M176 128a48 48 0 1 1-48-48a48 48 0 0 1 48 48" opacity="0.2"/><path d="M140 128a12 12 0 1 1-12-12a12 12 0 0 1 12 12"/></g></svg></div>
                                <div className="fw-light">Free Source code</div>
                            </div>
                            <div className="d-flex align-items-center">
                                <div><svg xmlns="http://www.w3.org/2000/svg" width="2em" height="2em" viewBox="0 0 256 256"><g fill="currentColor"><path d="M176 128a48 48 0 1 1-48-48a48 48 0 0 1 48 48" opacity="0.2"/><path d="M140 128a12 12 0 1 1-12-12a12 12 0 0 1 12 12"/></g></svg></div>
                                <div className="fw-light">guarantee 1 year</div>
                            </div>
                        </div>
                    </div>   
                </div>    
            </div>      
            <h3 className="fw-light text-center mt-5 mb-4">
                Buy source code from us.
            </h3>         
            <div className="container">
                <div className="row">
                    <div className="col-md-3 mb-5">
                        <div className="card card-neon p-3">
                            <h6 className="fw-light font-grad">Python, PHP, & Mysql</h6>
                            <h4>Authanticate With Face</h4>
                            <div className="d-flex align-items-center">
                                <div><svg xmlns="http://www.w3.org/2000/svg" width="2em" height="2em" viewBox="0 0 256 256"><g fill="currentColor"><path d="M176 128a48 48 0 1 1-48-48a48 48 0 0 1 48 48" opacity="0.2"/><path d="M140 128a12 12 0 1 1-12-12a12 12 0 0 1 12 12"/></g></svg></div>
                                <div className="fw-light">Price 1.500.000 IDR</div>
                            </div>
                            <div className="d-flex align-items-center">
                                <div><svg xmlns="http://www.w3.org/2000/svg" width="2em" height="2em" viewBox="0 0 256 256"><g fill="currentColor"><path d="M176 128a48 48 0 1 1-48-48a48 48 0 0 1 48 48" opacity="0.2"/><path d="M140 128a12 12 0 1 1-12-12a12 12 0 0 1 12 12"/></g></svg></div>
                                <div className="fw-light">Free Setup</div>
                            </div>
                            <a href="https://wa.me/6285174072416?text=order" className="btn btn-outline-light mt-3">Buy</a>
                        </div>
                    </div>
                    <div className="col-md-3 mb-5">
                        <div className="card card-neon p-3">
                            <h6 className="fw-light font-grad">Laravel & Mysql</h6>
                            <h4>Blog & News</h4>
                            <div className="d-flex align-items-center">
                                <div><svg xmlns="http://www.w3.org/2000/svg" width="2em" height="2em" viewBox="0 0 256 256"><g fill="currentColor"><path d="M176 128a48 48 0 1 1-48-48a48 48 0 0 1 48 48" opacity="0.2"/><path d="M140 128a12 12 0 1 1-12-12a12 12 0 0 1 12 12"/></g></svg></div>
                                <div className="fw-light">Price 500.000 IDR</div>
                            </div>
                            <div className="d-flex align-items-center">
                                <div><svg xmlns="http://www.w3.org/2000/svg" width="2em" height="2em" viewBox="0 0 256 256"><g fill="currentColor"><path d="M176 128a48 48 0 1 1-48-48a48 48 0 0 1 48 48" opacity="0.2"/><path d="M140 128a12 12 0 1 1-12-12a12 12 0 0 1 12 12"/></g></svg></div>
                                <div className="fw-light">Free Setup</div>
                            </div>
                            <a href="https://wa.me/6285174072416?text=order" className="btn btn-outline-light mt-3">Buy</a>
                        </div>
                    </div>
                    <div className="col-md-3 mb-5">
                        <div className="card card-neon p-3">
                            <h6 className="fw-light font-grad">React, Express & Socket.io</h6>
                            <h4>Chat Realtime</h4>
                            <div className="d-flex align-items-center">
                                <div><svg xmlns="http://www.w3.org/2000/svg" width="2em" height="2em" viewBox="0 0 256 256"><g fill="currentColor"><path d="M176 128a48 48 0 1 1-48-48a48 48 0 0 1 48 48" opacity="0.2"/><path d="M140 128a12 12 0 1 1-12-12a12 12 0 0 1 12 12"/></g></svg></div>
                                <div className="fw-light">Price 800.000 IDR</div>
                            </div>
                            <div className="d-flex align-items-center">
                                <div><svg xmlns="http://www.w3.org/2000/svg" width="2em" height="2em" viewBox="0 0 256 256"><g fill="currentColor"><path d="M176 128a48 48 0 1 1-48-48a48 48 0 0 1 48 48" opacity="0.2"/><path d="M140 128a12 12 0 1 1-12-12a12 12 0 0 1 12 12"/></g></svg></div>
                                <div className="fw-light">Free Setup</div>
                            </div>
                            <a href="https://wa.me/6285174072416?text=order" className="btn btn-outline-light mt-3">Buy</a>
                        </div>
                    </div>
                    <div className="col-md-3 mb-5">
                        <div className="card card-neon p-3">
                            <h6 className="fw-light font-grad">Express & Nodemail</h6>
                            <h4>Email Sender</h4>
                            <div className="d-flex align-items-center">
                                <div><svg xmlns="http://www.w3.org/2000/svg" width="2em" height="2em" viewBox="0 0 256 256"><g fill="currentColor"><path d="M176 128a48 48 0 1 1-48-48a48 48 0 0 1 48 48" opacity="0.2"/><path d="M140 128a12 12 0 1 1-12-12a12 12 0 0 1 12 12"/></g></svg></div>
                                <div className="fw-light">Price 500.000 IDR</div>
                            </div>
                            <div className="d-flex align-items-center">
                                <div><svg xmlns="http://www.w3.org/2000/svg" width="2em" height="2em" viewBox="0 0 256 256"><g fill="currentColor"><path d="M176 128a48 48 0 1 1-48-48a48 48 0 0 1 48 48" opacity="0.2"/><path d="M140 128a12 12 0 1 1-12-12a12 12 0 0 1 12 12"/></g></svg></div>
                                <div className="fw-light">Free Setup</div>
                            </div>
                            <a href="https://wa.me/6285174072416?text=order" className="btn btn-outline-light mt-3">Buy</a>
                        </div>
                    </div>
                </div>
                <div className="w-100 mt-3 d-flex justify-content-center">
                    <button className="btn btn-light">See All Product</button>
                </div>
            </div>
            <br />
            <h3 className="fw-light text-center mt-5 mb-4">
                Our Partner.
            </h3>
            <div className="pb-5">
                {/* <h6 className="text-center text-gray">partners development</h6> */}
                <div className="d-flex justify-content-center flex-wrap w-100 gap-5 py-3 align-items-center">
                    <img src={a} height="40px" className="partner" alt="partner" />
                    <img src={b} height="40px" className="partner" alt="partner" />
                    <img src={c} height="40px" className="partner" alt="partner" />
                    <img src={d} height="20px" className="partner" alt="partner" />
                    <img src={e} height="40px" className="partner" alt="partner" />
                </div>
            </div>
            <div className="container mt-5">
                <div className="row">
                    <div className="col-md-6">
                        <div id="carouselExampleCaptions" className="carousel slide" data-bs-ride="carousel">   
                            <div className="carousel-indicators">
                                <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                                <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="1" aria-label="Slide 2"></button>
                                <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="2" aria-label="Slide 3"></button>
                                <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="3" aria-label="Slide 4"></button>
                                <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="4" aria-label="Slide 5"></button>
                                <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="5" aria-label="Slide 6"></button>
                                <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="6" aria-label="Slide 7"></button>
                                <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="7" aria-label="Slide 8"></button>
                                <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="8" aria-label="Slide 9"></button>
                                <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="9" aria-label="Slide 10"></button>
                                <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="10" aria-label="Slide 11"></button>
                                <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="11" aria-label="Slide 12"></button>
                                <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="12" aria-label="Slide 13"></button>
                                <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="13" aria-label="Slide 14"></button>
                                <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="14" aria-label="Slide 15"></button>
                                <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="15" aria-label="Slide 16"></button>
                            </div>
                            <div className="carousel-inner">
                                <div className="carousel-item rounded position-relative active">
                                <img src={porto15} className="d-block w-100 rounded" alt="porto" />
                                <div className="carousel-caption d-none d-md-block bg-porto">
                                    <h5>Magicrylic App</h5>
                                    <p className="fw-light">E-cataloge App 2023-2024</p>
                                </div>
                                </div>
                                <div className="carousel-item rounded position-relative">
                                <img src={porto16} className="d-block w-100 rounded" alt="porto" />
                                <div className="carousel-caption d-none d-md-block bg-porto">
                                    <h5>Camakapu App</h5>
                                    <p className="fw-light">Goverment NTT 2024</p>
                                </div>
                                </div>
                                <div className="carousel-item rounded position-relative">
                                <img src={porto1} className="d-block w-100 rounded" alt="porto" />
                                <div className="carousel-caption d-none d-md-block bg-porto">
                                    <h5>Siap dan Sitihanum</h5>
                                    <p className="fw-light">Goverment Kendari 2023-2024</p>
                                </div>
                                </div>
                                <div className="carousel-item rounded position-relative">
                                <img src={porto2} className="d-block w-100 rounded" alt="porto" />
                                <div className="carousel-caption d-none d-md-block bg-porto">
                                    <h5>Sipeta guru</h5>
                                    <p className="fw-light">Goverment Kendari 2023-2024</p>
                                </div>
                                </div>
                                <div className="carousel-item rounded position-relative">
                                <img src={porto3} className="d-block w-100 rounded" alt="porto" />
                                <div className="carousel-caption d-none d-md-block bg-porto">
                                    <h5>Sieva APBD</h5>
                                    <p className="fw-light">Goverment Kendari 2023-2024</p>
                                </div>
                                </div>
                                <div className="carousel-item rounded position-relative">
                                <img src={porto4} className="d-block w-100 rounded" alt="porto" />
                                <div className="carousel-caption d-none d-md-block bg-porto">
                                    <h5>PDAM Bontang</h5>
                                    <p className="fw-light">PDAM Bontang 2021-2024</p>
                                </div>
                                </div>
                                <div className="carousel-item rounded position-relative">
                                <img src={porto5} className="d-block w-100 rounded" alt="porto" />
                                <div className="carousel-caption d-none d-md-block bg-porto">
                                    <h5>CRUD Studio</h5>
                                    <p className="fw-light">Software House 2023</p>
                                </div>
                                </div>
                                <div className="carousel-item rounded position-relative">
                                <img src={porto6} className="d-block w-100 rounded" alt="porto" />
                                <div className="carousel-caption d-none d-md-block bg-porto">
                                    <h5>Mandiri Inhealth</h5>
                                    <p className="fw-light">Subkon Pollux 2023</p>
                                </div>
                                </div>
                                <div className="carousel-item rounded position-relative">
                                <img src={porto7} className="d-block w-100 rounded" alt="porto" />
                                <div className="carousel-caption d-none d-md-block bg-porto">
                                    <h5>Carbon Travel</h5>
                                    <p className="fw-light">Subkon Entercode 2023</p>
                                </div>
                                </div>
                                <div className="carousel-item rounded position-relative">
                                <img src={porto8} className="d-block w-100 rounded" alt="porto" />
                                <div className="carousel-caption d-none d-md-block bg-porto">
                                    <h5>CGS CIMB</h5>
                                    <p className="fw-light">Subkon Pollux 2023</p>
                                </div>
                                </div>
                                <div className="carousel-item rounded position-relative">
                                <img src={porto9} className="d-block w-100 rounded" alt="porto" />
                                <div className="carousel-caption d-none d-md-block bg-porto">
                                    <h5>Genbox Academy</h5>
                                    <p className="fw-light">Our Academy web</p>
                                </div>
                                </div>
                                <div className="carousel-item rounded position-relative">
                                <img src={porto10} className="d-block w-100 rounded" alt="porto" />
                                <div className="carousel-caption d-none d-md-block bg-porto">
                                    <h5>CEO Web</h5>
                                    <p className="fw-light">Our CEO web</p>
                                </div>
                                </div>
                                <div className="carousel-item rounded position-relative">
                                <img src={porto11} className="d-block w-100 rounded" alt="porto" />
                                <div className="carousel-caption d-none d-md-block bg-porto">
                                    <h5>Genbox Academy</h5>
                                    <p className="fw-light">Our Payment web</p>
                                </div>
                                </div>
                                <div className="carousel-item rounded position-relative">
                                <img src={porto12} className="d-block w-100 rounded" alt="porto" />
                                <div className="carousel-caption d-none d-md-block bg-porto">
                                    <h5>Genbox Enterprise</h5>
                                    <p className="fw-light">Our web</p>
                                </div>
                                </div>
                                <div className="carousel-item rounded position-relative">
                                <img src={porto13} className="d-block w-100 rounded" alt="porto" />
                                <div className="carousel-caption d-none d-md-block bg-porto">
                                    <h5>Slicing Design</h5>
                                    <p className="fw-light">Subkon CRUD Studio</p>
                                </div>
                                </div>
                                <div className="carousel-item rounded position-relative">
                                <img src={porto14} className="d-block w-100 rounded" alt="porto" />
                                <div className="carousel-caption d-none d-md-block bg-porto">
                                    <h5>Gramedia Affiliate</h5>
                                    <p className="fw-light">Store app 2022</p>
                                </div>
                                </div>
                            </div>
                            {/* <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
                                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                <span className="visually-hidden">Previous</span>
                            </button>
                            <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
                                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                <span className="visually-hidden">Next</span>
                            </button> */}
                        </div>
                    </div>
                    <div className="mt-3 col-md-6 d-flex flex-column justify-content-end ">
                        <h1 className="text-start mb-4 fs-1">
                            Our Portfolio.
                        </h1>
                        <p className="fw-light mb-5">The projects that our company works on include full contracts, subcontracts, maintenance, adding several features, and also the owner's personal projects.</p>
                        <a href="https://github.com/risanussy" className="btn text-white fs-5 m-0 p-0 text-start fw-light">our ceo github <i className="fa-solid fa-arrow-up-right-from-square ms-2"></i></a>
                    </div>
                </div>
            </div>
            <br />
            <br />
            <br />
            <div className="w-100 bg-resource pb-5">
                <div className="container py-5">
                    <h1 className="text-center">
                        We also have profesional resource<br/>for yours business or team
                    </h1>
                    <div className="d-flex justify-content-center pt-5 gap-3">
                    <a href="https://wa.me/6285174072416" className="rounded px-3 py-2 text-decoration-none btn-main">Chat us</a>
                    <a href="https://www.instagram.com/genbox.academy" className="rounded px-3 py-2 text-decoration-none btn-outline-main">our activity</a>
                    </div>
                    <img src="https://cdn.prod.website-files.com/637359c81e22b715cec245ad/662810ffa88d9c774f996ae0_green-earth.webp" alt="earth" className="w-100"style={{ mixBlendMode: 'lighten' }} />
                </div>
            </div>
            <div className="w-100 bg-grad py-3">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <h1>Let's schedule our time</h1>
                        </div>
                        <div className="col-md-6">
                            <form className="h-100 d-flex align-items-center gap-3" onSubmit={sendEmail}>
                                <input type="text" onInput={(e) => setSubject(e.target.value)} placeholder="subject email" className="form-control" />
                                <button className="btn btn-outline-light">Send</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <footer className="bg-footer py-3">
                <div className="container">
                    <div className="row">
                        <div className="col-md-4 mb-3">
                            <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15859.106779456873!2d106.8922225!3d-6.4227311!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e69ebff1177fb53%3A0x56e21f10fb9d1fe6!2sHybrid%20Office%20-%20Virtual%20Office%20Depok!5e0!3m2!1sid!2sid!4v1723240872303!5m2!1sid!2sid" title="map" width="100%" className="rounded" height="180" style={{border:0}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                        </div>
                        <div className="col-md-4 mb-3">
                            <small className="fw-light">PT Generasi Digital Nasional</small>
                            <h1 className="font-grad">Genbox Enterprise</h1>
                            <br />
                            <p className="fw-light">Developer Technology Technology Indonesian. Invest Digital Product for best future</p>
                        </div>
                        <div className="col-md-4 mb-3">
                            <h3 className="fw-light mb-3">Contact</h3>
                            
                            <div className="d-flex align-items-center mb-2">
                                <div><svg xmlns="http://www.w3.org/2000/svg" width="2em" height="2em" viewBox="0 0 256 256"><g fill="currentColor"><path d="M176 128a48 48 0 1 1-48-48a48 48 0 0 1 48 48" opacity="0.2"/><path d="M140 128a12 12 0 1 1-12-12a12 12 0 0 1 12 12"/></g></svg></div>
                                <div className="fw-light">hello@genbox.id</div>
                            </div>
                            <div className="d-flex align-items-center mb-2">
                                <div><svg xmlns="http://www.w3.org/2000/svg" width="2em" height="2em" viewBox="0 0 256 256"><g fill="currentColor"><path d="M176 128a48 48 0 1 1-48-48a48 48 0 0 1 48 48" opacity="0.2"/><path d="M140 128a12 12 0 1 1-12-12a12 12 0 0 1 12 12"/></g></svg></div>
                                <div className="fw-light">genbox22@gmail.com</div>
                            </div>
                            <div className="d-flex align-items-center mb-2">
                                <div><svg xmlns="http://www.w3.org/2000/svg" width="2em" height="2em" viewBox="0 0 256 256"><g fill="currentColor"><path d="M176 128a48 48 0 1 1-48-48a48 48 0 0 1 48 48" opacity="0.2"/><path d="M140 128a12 12 0 1 1-12-12a12 12 0 0 1 12 12"/></g></svg></div>
                                <div className="fw-light">+62 851 7407 2416</div>
                            </div>
                        </div>
                    </div>
                </div>
                <center><i className="fw-light d-inline-block">&copy; copyright genbox</i></center>
            </footer>
        </>
    );
}
